import isValid from 'date-fns/isValid';
import {
  checarHorarios,
  checarIntervaloAgendamentos,
} from '../../../../../../services/atendimentos';
import { useScheduleOnline } from '../../../../../../Context/SchedulerOnlineContext';
import getCookie from '../../../../../../utils/getCookies';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

export const useScheduler = () => {
  const {
    setScheduleService,
    setScheduleServiceUser,
    setUnitySelected,
    selectedDate,
    availableDates,
    setAvailableDates,
    handleDateChange,
    unitySelected,
    serviceData,
  } = useScheduleOnline();

  const getSchedulers = async (date: Date, unity: string): Promise<void> => {
    setScheduleService(undefined);
    setScheduleServiceUser(undefined);
    if (isValid(date)) {
      try {
        const { data } = await checarHorarios(date, unity, serviceData.id);
        setScheduleService(data);
      } catch (err) {
        if (err.response) {
          setScheduleService(err.response.data.message);
        } else if (err.response?.data?.[0]) {
          setScheduleService(err.response?.data?.[0]);
        } else {
          setScheduleService('Algo inesperado ocorreu.');
        }
      }
    } else {
      setScheduleService(undefined);
    }
  };

  const getStartAndEndOfMonth = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth();

    const data_inicio = new Date(year, month, 1).toISOString().split('T')[0];
    const data_fim = new Date(year, month + 1, 0).toISOString().split('T')[0];

    return { data_inicio, data_fim };
  };

  const handleChangeUnit = (event: React.ChangeEvent<HTMLInputElement>) => {
    setScheduleServiceUser(undefined);
    setUnitySelected(event.target.value as string);
    getSchedulers(selectedDate, event.target.value as string);
  };

  const handleCheckInterval = async (
    startDate: string,
    endDate: string,
    unitySelected: string,
    serviceId: number,
  ) => {
    const token = getCookie('gov_access_token_sso');
    if (!token) {
      console.error('Token not found');
      return;
    }

    const { data_inicio, data_fim } =
      startDate && endDate
        ? { data_inicio: startDate, data_fim: endDate }
        : getStartAndEndOfMonth(new Date());

    try {
      const response = await checarIntervaloAgendamentos(
        token,
        serviceId.toString(),
        'Online',
        unitySelected,
        data_inicio,
        data_fim,
      );
      setAvailableDates(response?.data);
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };

  const handleChangeDate = (
    date: MaterialUiPickersDate | null,
    value?: string | null,
  ) => {
    if (date && isValid(date)) {
      handleDateChange(date);
      if (unitySelected) {
        getSchedulers(date, unitySelected);
      }
    }
  };

  const isDateAvailable = (date: Date) => {
    const formattedDate = date.toISOString().split('T')[0];
    return availableDates?.includes(formattedDate as never);
  };

  const handleMonthChange = async (
    date: Date,
    unitySelected: string,
    serviceId: number,
  ) => {
    const { data_inicio, data_fim } = getStartAndEndOfMonth(date);
    await handleCheckInterval(data_inicio, data_fim, unitySelected, serviceId);
  };

  return {
    getSchedulers,
    handleChangeUnit,
    handleCheckInterval,
    getStartAndEndOfMonth,
    handleChangeDate,
    isDateAvailable,
    handleMonthChange,
  };
};
