import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { useStyles } from "./styles";
import { useScheduleOnline } from "../../../../../../Context/SchedulerOnlineContext";
import { useActions } from "./useActions";

export default function Actions() {
  const { executeFlow } = useActions()
  const {
    handleChangeStep,
    activeStep,
    stateDataInitStepsScheduling,
    unitySelected,
    selectedDate,
    scheduleSelected,
    scheduleService,
    nameDependente,
    cpfDependente,
    birthDateDependente,
    grauDependenteSelected,
    serviceData,
    files
  } = useScheduleOnline()
  const classes = useStyles();

  const countSteps = stateDataInitStepsScheduling.filter((steps) => steps.active)?.length - 2

  const currentCountSteps = stateDataInitStepsScheduling.filter((steps) => steps.active)

  if (activeStep <= 0) {
    return null
  }

  const filesRequired = serviceData?.configuracao_agendamento?.anexo && files?.length === 0;

  const handleValidation = (): boolean => {
    switch (currentCountSteps[activeStep].title) {
      case 'Agendamento':
        return !unitySelected ||
          !selectedDate ||
          !scheduleSelected ||
          typeof scheduleService === 'string' ||
          typeof scheduleService === 'undefined'
      case 'Dados do dependente':
        return !nameDependente ||
          !cpfDependente ||
          !birthDateDependente ||
          !grauDependenteSelected
      case 'Dados complementares':
        return filesRequired
      default:
        return false
    }
  }

  return (
    <Box className={classes.container}>
      <Button
        onClick={() => handleChangeStep('prev')}
        type="button"
        className={classes.buttonOutline}>
        Voltar
      </Button>
      <Button
        type="button"
        className={classes.buttonFilled}
        disabled={handleValidation()}
        onClick={() => {
          if (activeStep - 1 === countSteps) {
            return executeFlow()
          } else {
            return handleChangeStep('next')
          }
        }}>
        {activeStep - 1 === countSteps ? 'Finalizar' : 'Avançar'}
      </Button>
    </Box>
  )
}
