import makeStyles from '@material-ui/styles/makeStyles';
import theme from '../../../../services/theme-service';
import StepConnector from '@material-ui/core/StepConnector';
import withStyles from '@material-ui/styles/withStyles';

export const useStyles = makeStyles({
  rootDialog: {
    '& .MuiDialogContent-root': {
      overflowY: 'hidden',
    },
  },
  containerTextError: {
    margin: '8px 32px',
  },
  buttonClose: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0.5),
    color: '#fff',
  },
  header: {
    background: theme.palette.primary.main,
  },
  textHeader: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    width: '90%',
    color: '#fff',
  },
  boxConcludedMain: {
    backgroundColor: '#fff',
  },
  textSchedule: {
    fontFamily: 'Roboto',
    color: '#333',
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(24),
    marginTop: 30,
    textAlign: 'center',
  },
  textSubtitleSchedule: {
    fontFamily: 'Roboto',
    color: 'rgba(112, 110, 122, 1)',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    marginTop: 8,
  },
  buttonPrimary: {
    backgroundColor: theme.palette.primary.main,
    color: '#333',
    maxWidth: 278,
    width: '100%',
    height: 36,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  textButtonPrimary: {
    fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    textTransform: 'uppercase',
    color: '#fff',
  },
  buttonOutlined: {
    width: '100%',
    maxWidth: 250,
    height: 36,
    padding: '6px 16px',
    marginLeft: '0px !important',
    textDecoration: 'none',
    borderRadius: 4,
    borderColor: theme.palette.primary.main,
  },
  textButtonOutlined: {
    borderRadius: 10,
    // padding: '6px 16px',
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.main,
    fontWeight: 500,
    textTransform: 'uppercase',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  titleLabel: {
    color: '#333',
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(10),
    fontWeight: 600,
  },
});

export const CustomConnector = withStyles({
  active: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  completed: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  line: {
    borderColor: '#ccc',
    borderTopWidth: 2,
    borderRadius: 1,
  },
})(StepConnector);
