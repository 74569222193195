import React from "react"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import ChevronRight from "@material-ui/icons/ChevronRight"
import Person from "@material-ui/icons/Person"
import SupervisorAccount from "@material-ui/icons/SupervisorAccount"
import { useStyles } from "./styles"
import { useScheduleOnline } from "../../../../../../Context/SchedulerOnlineContext";

export default function Init() {
  const classes = useStyles();
  const { serviceData } = useScheduleOnline();
  const hasMotivo = serviceData?.configuracao_agendamento?.motivos?.some((motivo) => motivo.ativo);
  const { handleChangeStep, typeSchedule, handleChangeTypeScheduleOptions } = useScheduleOnline()

  return (
    <Box className={classes.content}>
      <h1 className={classes.title}>Escolha quem será atendido</h1>
      <Box className={typeSchedule === 'me' ? `${classes.contentOptionActive}` : classes.contentOption} onClick={() => {
        handleChangeTypeScheduleOptions({
          type: 'me',
          motivo: hasMotivo
        })
        handleChangeStep('next')
      }}>
        <Box className={classes.option}>
          <div className={classes.iconOfOptionDefault}>
            <Person />
          </div>
          <div>
            <Typography className={classes.optionText}>Para mim</Typography>
            <p className={classes.optionDescription}>O atendimento será realizado diretamente para você</p>
          </div>
        </Box>
        <span>
          <ChevronRight className={classes.arrowIcon} />
        </span>
      </Box>
      <Box className={typeSchedule === 'dependent' ? `${classes.contentOptionActive}` : classes.contentOption} onClick={() => {
        handleChangeTypeScheduleOptions({
          type: 'dependent',
          motivo: hasMotivo,
        })
        handleChangeStep('next')
      }}>
        <Box className={classes.option}>
          <div className={classes.iconOfOptionDefault}>
            <SupervisorAccount />
          </div>
          <div>
            <Typography className={classes.optionText}>Para um dependente</Typography>
            <p className={classes.optionDescription}>Selecione se o atendimento for para um filho ou familiar</p>
          </div>
        </Box>
        <span>
          <ChevronRight className={classes.arrowIcon} />
        </span>
      </Box>
    </Box>
  )
}