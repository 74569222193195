import makeStyles from '@material-ui/styles/makeStyles';
import theme from '../../../../../../services/theme-service';

export const useStyles = makeStyles({
  root: {},
  box: {
    borderRadius: 4,
    border: `1px solid rgba(225, 230, 239, 1)`,
    padding: '24px',
    margin: '24px 32px 0px 32px',
  },
  titleBox: {
    fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    textTransform: 'uppercase',
    margin: '2px 0px 30px 0px',
  },
  formControl: {
    marginBottom: 20,
    margin: theme.spacing(1),
    width: '100%',
  },
  textLabel: {},
  menuItem: {
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
      color: theme.palette.primary.main,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
      },
    },
  },
  adornedEnd: {
    paddingRight: 0,
  },
});
