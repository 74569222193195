import { FileObject } from 'material-ui-dropzone';
import { useScheduleOnline } from '../../../../../../Context/SchedulerOnlineContext';

export const useReason = () => {
  const {
    setFormDataReason,
    formDataReason,
    setIsErrorInUpload,
    files,
    setFiles,
    setIsAttachmentRequired,
  } = useScheduleOnline();

  const handleSelectChangeReasons = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFormDataReason({ ...formDataReason, reasonService: event.target.value });
  };

  const handleChangeDescription = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    setFormDataReason({
      ...formDataReason,
      descriptionProblem: event.target.value,
    });
  };

  const handleUploadsAlert = (variant: string) => {
    if (variant === 'error') {
      setIsErrorInUpload(true);
    } else {
      setIsErrorInUpload(false);
    }
  };

  const removeFile = (file: FileObject) => {
    const fileFilter = files.filter(
      fileFiltering => fileFiltering.data !== file.data,
    );
    setFiles(fileFilter);
  };

  const handleChangeFile = (filesUpload: FileObject[]) => {
    setFiles(filesUpload);
    if (filesUpload.length > 0) {
      setIsAttachmentRequired(false);
    }
  };

  return {
    handleSelectChangeReasons,
    handleChangeDescription,
    handleUploadsAlert,
    removeFile,
    handleChangeFile,
  };
};
