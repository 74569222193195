import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import React from "react"
import { useStyles } from "./styles"
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"
import Card from "@material-ui/core/Card"
import IconButton from "@material-ui/core/IconButton";
import { useScheduleOnline } from "../../../../../../Context/SchedulerOnlineContext";
import { useReason } from "./useReason";
import { DropzoneAreaBase } from "material-ui-dropzone";
import { MdDeleteForever } from "react-icons/md";

export default function Reason() {
  const classes = useStyles();
  const {
    formDataReason,
    serviceData,
    files,
    isErrorInUpload,
    setIsErrorInUpload,
    isAttachmentRequired
  } = useScheduleOnline()

  const {
    handleSelectChangeReasons,
    handleChangeDescription,
    handleUploadsAlert,
    removeFile,
    handleChangeFile
  } = useReason();

  return (
    <Box className={classes.box}>
      <Typography className={classes.titleBox}>
        Motivos do atendimento
      </Typography>
      <FormControl
        variant='outlined'
        fullWidth
        className={classes.formControlList}
      >
        <InputLabel id='demo-simple-select-outlined-label'>
          Selecione o motivo
        </InputLabel>
        <Select
          labelId='demo-simple-select-outlined-label'
          id='demo-simple-select-outlined'
          value={formDataReason.reasonService}
          onChange={handleSelectChangeReasons}
          label='Dados Complementares'
        >
          <MenuItem className={classes.menuItem} value=''>
            <em>Nenhum</em>
          </MenuItem>
          {serviceData?.configuracao_agendamento?.motivos
            ?.filter(motivo => motivo.ativo)
            ?.map(motivo => (
              <MenuItem
                key={motivo.id}
                className={classes.menuItem}
                value={motivo.titulo}
              >
                {motivo.titulo}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {serviceData?.configuracao_agendamento?.descricao_problema && (
        <TextField
          multiline
          variant="outlined"
          label="Descrição"
          placeholder={
            serviceData?.configuracao_agendamento?.descricao_problema
          }
          minRows={4}
          fullWidth
          className={classes.textarea}
          value={formDataReason.descriptionProblem}
          onChange={handleChangeDescription}
          helperText={serviceData?.configuracao_agendamento?.descricao_problema}
        />
      )}

      {(serviceData?.configuracao_agendamento?.anexo ||
        serviceData?.configuracao_agendamento?.descricao_anexo) && (
          <>
            <Box style={{ padding: '0px 0px 10px 0px' }}>
              {serviceData.configuracao_agendamento?.descricao_anexo}
            </Box>
            <div>
              {/* <Button
                disabled={
                  !unitySelected || typeof scheduleServiceUser === 'string'
                }
                onClick={(): void => setOpenUploads(true)}
                style={{
                  borderRadius: 4,
                  // color: colors.accentColor,
                  // border: `1px solid ${colors.accentColor}`,
                  display: openUploads ? 'none' : 'block',
                  textTransform: 'uppercase',
                }}
              >
                Adicionar arquivo
              </Button> */}

              {/* <div
                // hidden={!openUploads}
                style={{
                  color: '#9E9E9E',
                  marginTop: 5,
                  marginLeft: 10,
                  width: '97%',
                }}
              > */}
              <DropzoneAreaBase
                onAdd={handleChangeFile}
                fileObjects={files}
                acceptedFiles={['image/jpeg', 'image/png', '.pdf']}
                showPreviewsInDropzone={false}
                dropzoneText={`Faça upload ou solte arquivos aqui\nTamanho máximo: 30MB`}
                showFileNames={false}
                showFileNamesInPreview={false}
                dropzoneParagraphClass={classes.text}
                showAlerts={false}
                filesLimit={10}
                maxFileSize={31457280}
                classes={{
                  icon: classes.iconUpload,
                  root: classes.rootUpload,
                }}
                onAlert={(_, variant) => handleUploadsAlert(variant)}
              />
              {/* <Typography className={classes.infoText}>
                Tamanho máximo: 30MB
              </Typography> */}

              {files?.map(file => (
                <Card
                  className={classes.cardUploads}
                  key={file.file.name}
                >
                  {file.file.name}
                  <IconButton onClick={() => removeFile(file)}>
                    <MdDeleteForever
                      style={{ color: 'red', width: 24, height: 24 }}
                    />
                  </IconButton>
                </Card>
              ))}

              {isErrorInUpload && (
                <Card className={classes.errorUploadCard}>
                  <Typography className={classes.errorTextStyle}>
                    Arquivo inválido, ou excede o limite de tamanho.
                  </Typography>
                  <IconButton onClick={() => setIsErrorInUpload(false)}>
                    <MdDeleteForever
                      style={{
                        color: '#F03D3D',
                        width: 24,
                        height: 24,
                      }}
                    />
                  </IconButton>
                </Card>
              )}
            </div>
            {isAttachmentRequired && (
              <Typography className={classes.errorTextStyle}>
                É obrigatório adicionar um anexo.
              </Typography>
            )}
          </>
        )}
    </Box>
  )
}