/* eslint-disable @typescript-eslint/no-unused-vars */
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormHelperText from "@material-ui/core/FormHelperText"
import IconButton from "@material-ui/core/IconButton"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import Stepper from "@material-ui/core/Stepper"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Dialog from "@material-ui/core/Dialog"

import React, { useEffect } from 'react';
import {
  MdCheckCircle,
  MdClose,
  MdMouse,
} from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { SearchI } from '../../../SearchService';
import {
  checarDisponibilidadeAgendamentos,
} from '../../../../services/atendimentos';

import getCookie from '../../../../utils/getCookies';
import Init from './components/Init';
import { useScheduleOnline } from '../../../../Context/SchedulerOnlineContext';
import Scheduling from './components/Scheduling';
import Actions from './components/Actions';
import Dependent from './components/Dependent';
import Reason from './components/Reason';
import { CustomConnector, useStyles } from './styles';
import { Schedule, ScheduleUser } from "../../../../types/scheduling"

interface Props {
  open: boolean;
  service: SearchI;
  handleClose: () => void;
}

export default function ModalAttendanceOnline({
  open,
  service,
  handleClose,
}: Props): JSX.Element {
  const {
    activeStep,
    setUnitsList,
    stateDataInitStepsScheduling,
    setServiceData,
    setUnitySelected,
    setFiles,
    loading,
    savedSchedule,
    setSavedSchedule,
    handleResetValues,
    scheduleServiceUser,
    setScheduleServiceUser,
    scheduleService,
    availableDates,
    setScheduleService
  } = useScheduleOnline()

  const classes = useStyles();
  const history = useHistory();


  const handleListScheduleDates = async (serviceId: string) => {
    const token = getCookie('gov_access_token_sso');
    if (!token) {
      console.error('Token not found');
      return;
    }

    try {
      const response = await checarDisponibilidadeAgendamentos(
        token,
        serviceId,
        'Online',
      );
      setUnitsList(response.data);
    } catch (error) {
      console.error('Error fetching available dates:', error);
    }
  };

  const handleFinally = () => {
    history.push('/workspace/meus_agendamentos');
  };


  const handleDialogClose = (_event: any, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    handleClose();
  };

  const handlePages = () => {
    const filteredSteps = stateDataInitStepsScheduling?.filter((steps) => steps.active);
    const currentStep = filteredSteps.find((step) => step.title === filteredSteps[activeStep]?.title);

    switch (currentStep?.title) {
      case 'Inicio':
        return <Init />
      case 'Agendamento':
        return <Scheduling />;
      case 'Dados do dependente':
        return <Dependent />;
      case 'Dados complementares':
        return <Reason />;
      default:
        return null;
    }
  }

  useEffect(() => {
    setUnitySelected('');
    setScheduleServiceUser(undefined);
    setScheduleService(undefined);
    setFiles([]);
    handleListScheduleDates(service?.id.toString());
    setSavedSchedule(null)
    handleResetValues()
  }, [open]);

  useEffect(() => {
    if (service) {
      setServiceData(service)
    }
  }, [service])

  const renderTextError = () => {
    const getErrorMessage = <T extends string | ScheduleUser | Schedule>(message?: T) =>
      typeof message === 'string' ? (
        <Box className={classes.containerTextError}>
          <FormHelperText error>
            <strong>Atenção: </strong>{message}
          </FormHelperText>
        </Box>
      ) : null;

    if (scheduleServiceUser) return getErrorMessage(scheduleServiceUser);
    if (scheduleService && availableDates?.length !== 0) return getErrorMessage(scheduleService);

    return null
  }

  // Renderiza o Stepper e os conteúdos relacionados
  const renderStepper = () => (
    <>
      {activeStep === 0 ? (
        <Init />
      ) : (
        <>
          {activeStep <= stateDataInitStepsScheduling?.length && (
            <Stepper activeStep={activeStep - 1} connector={<CustomConnector />}>
              {stateDataInitStepsScheduling
                ?.filter((step) => step.active && step.title !== "Inicio")
                .map((step) => (
                  <Step key={step.title}>
                    <StepLabel className={classes.titleLabel}>{step.title}</StepLabel>
                  </Step>
                ))}
            </Stepper>
          )}
          {handlePages()}
          {renderTextError()}
        </>
      )}
      <Actions />
    </>
  );

  // Renderiza a tela de conclusão
  const renderConcludedScreen = () => (
    <div className={classes.boxConcludedMain}>
      <DialogContent style={{ textAlign: "center" }}>
        <Box>
          <MdCheckCircle
            style={{ color: "#3CD278", width: 80, height: 80, fontSize: 24 }}
          />
        </Box>
        <Typography className={classes.textSchedule}>
          Atendimento Agendado com Sucesso!
        </Typography>
        <Typography className={classes.textSubtitleSchedule}>
          Enviamos para você um comprovante,
          verifique seu Email.
          Não esqueça do seu horário marcado,
          você receberá uma notificação antes do seu atendimento iniciar.
        </Typography>
      </DialogContent>
      <DialogActions
        style={{
          margin: '32px 0px',
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: 'center'
        }}
      >
        <Button
          onClick={() => {
            handleClose();
            history.push("/");
          }}
          variant='outlined'
          className={classes.buttonOutlined}
        >
          <Typography className={classes.textButtonOutlined}>
            Voltar para a página inicial
          </Typography>
        </Button>
        <Button
          type='button'
          className={classes.buttonPrimary}
          onClick={handleFinally}>
          <Typography className={classes.textButtonPrimary}>
            Meus agendamentos
          </Typography>
        </Button>
      </DialogActions>
    </div>
  );

  const renderContent = () => {
    if (loading) {
      return (
        <DialogContent>
          <Box style={{ marginTop: 20, textAlign: "center", width: "100%" }}>
            <CircularProgress color="primary" />
          </Box>
        </DialogContent>
      );
    }

    if (savedSchedule) {
      return renderConcludedScreen();
    } else {
      return renderStepper();
    }
  }

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleDialogClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      classes={{ root: classes.rootDialog }}
    >
      {(!savedSchedule || !service.configuracao_agendamento.anexo) && (
        <IconButton onClick={handleClose} className={classes.buttonClose}>
          <MdClose style={{ width: 24, height: 24 }} />
        </IconButton>
      )}
      {!savedSchedule && (
        <DialogTitle className={classes.header}>
          <Typography className={classes.textHeader}>
            <MdMouse
              style={{
                marginRight: 15,
                width: 24,
                height: 24,
              }}
            />
            Atendimento online
          </Typography>
        </DialogTitle>
      )}
      {renderContent()}
    </Dialog>
  );
}