import makeStyles from '@material-ui/styles/makeStyles';
import theme from '../../../../../../services/theme-service';

export const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 24,
  },
  buttonFilled: {
    padding: '6px 16px',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '&[disabled]': {
      cursor: 'not-allowed',
      backgroundColor: '#d4d4d4',
    },
  },
  buttonOutline: {
    padding: '6px 16px',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  },
});
