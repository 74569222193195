import React, { useState, createContext, useContext, useReducer } from "react"
import {
  ActionPayloadSchedulingReducer,
  InitialStateSchedulingOnlineProps,
  ReasonStateProps,
  SavedScheduleProps,
  Schedule,
  ScheduleUser,
  TypeScheduleOptionsProps,
  UnityData
} from "../types/scheduling";
import subDays from "date-fns/subDays";
import { SearchI } from "../Pages/SearchService";
import { FileObject } from "material-ui-dropzone";

interface ScheduleOnlineProviderProps {
  children: React.ReactNode;
}

interface ScheduleOnlineContextType {
  activeStep: number;
  typeSchedule: string | null;
  unitsList: UnityData[] | [];
  setUnitsList: React.Dispatch<React.SetStateAction<UnityData[]>>;
  unitySelected: string;
  setUnitySelected: React.Dispatch<React.SetStateAction<string>>;
  scheduleService: string | Schedule | undefined;
  selectedDate: Date;
  availableDates: string[] | [];
  scheduleSelected: string;
  stateDataInitStepsScheduling: InitialStateSchedulingOnlineProps[];
  errorNameDependente: string;
  nameDependente: string;
  errorCpfDependente: string;
  cpfDependente: string;
  birthDateDependente: Date;
  grauDependenteSelected: string;
  formDataReason: ReasonStateProps;
  serviceData: SearchI | null;
  files: FileObject[];
  isErrorInUpload: boolean;
  isAttachmentRequired: boolean;
  loading: boolean;
  savedSchedule: SavedScheduleProps;
  setSavedSchedule: React.Dispatch<React.SetStateAction<SavedScheduleProps>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAttachmentRequired: React.Dispatch<React.SetStateAction<boolean>>;
  setIsErrorInUpload: React.Dispatch<React.SetStateAction<boolean>>;
  setFiles: React.Dispatch<React.SetStateAction<FileObject[]>>;
  setServiceData: React.Dispatch<React.SetStateAction<SearchI>>;
  setFormDataReason: React.Dispatch<React.SetStateAction<ReasonStateProps>>;
  setGrauDependenteSelected: React.Dispatch<React.SetStateAction<string>>;
  setBirthDateDependente: React.Dispatch<React.SetStateAction<Date>>;
  setCpfDependente: React.Dispatch<React.SetStateAction<string>>;
  setErrorCpfDependente: React.Dispatch<React.SetStateAction<string>>;
  setNameDependente: React.Dispatch<React.SetStateAction<string>>;
  setErrorNameDependente: React.Dispatch<React.SetStateAction<string>>;
  setScheduleSelected: React.Dispatch<React.SetStateAction<string>>;
  setAvailableDates: React.Dispatch<React.SetStateAction<string[]>>;
  handleDateChange: React.Dispatch<React.SetStateAction<Date>>;
  setScheduleService: React.Dispatch<React.SetStateAction<string | Schedule>>;
  scheduleServiceUser: string | ScheduleUser;
  setScheduleServiceUser: React.Dispatch<React.SetStateAction<string | ScheduleUser>>;
  handleChangeStep: (type: 'next' | 'prev') => void
  handleChangeTypeScheduleOptions: (params: TypeScheduleOptionsProps) => void;
  handleResetValues: () => void
}

export const ScheduleOnlineContext = createContext(
  {} as ScheduleOnlineContextType,
)

export const useScheduleOnline = () => useContext(ScheduleOnlineContext)

const initialState: InitialStateSchedulingOnlineProps[] = [
  {
    title: 'Inicio',
    step: 0,
    active: true,
    showLegend: false,
  },
  {
    title: 'Agendamento',
    step: 1,
    active: true,
    showLegend: true,
  },
  {
    title: 'Dados do dependente',
    step: 2,
    active: false,
    showLegend: false,
  },
  {
    title: 'Dados complementares',
    step: 3,
    active: true,
    showLegend: true,
  }
]

const schedulingReducer = (
  state: InitialStateSchedulingOnlineProps[],
  action: ActionPayloadSchedulingReducer
) => {
  // condição para exibir alguns steps mediante a api ou pelo tipo selecionado de agendamento
  const updateItem = (item: InitialStateSchedulingOnlineProps): InitialStateSchedulingOnlineProps => {
    const isDependentData = item.title === 'Dados do dependente';
    const isSchedulingReason = item.title === 'Dados complementares';

    switch (action.type) {
      case 'me':
        if (isDependentData) {
          return { ...item, active: false, showLegend: false };
        }
        if (!action.payload.motivo && isSchedulingReason) {
          return { ...item, active: false, showLegend: false };
        }
        break;
      case 'dependent':
        if (isDependentData) {
          return { ...item, active: true, showLegend: true };
        }
        if (!action.payload.motivo && isSchedulingReason) {
          return { ...item, active: false, showLegend: false };
        }
        break;
      default:
        return item;
    }

    return item
  }

  return state.map(updateItem)
}

export function ScheduleOnlineProvider({ children }: ScheduleOnlineProviderProps) {
  const [serviceData, setServiceData] = useState<SearchI | null>(null)
  const [step, setStep] = useState<number>(0);
  const [typeSchedule, setTypeSchedule] = useState<'me' | 'dependent' | null>(null)
  const [unitsList, setUnitsList] = useState<UnityData[]>([]);
  const [unitySelected, setUnitySelected] = useState<string>('');
  const [scheduleService, setScheduleService] = useState<
    string | Schedule | undefined
  >();
  const [scheduleServiceUser, setScheduleServiceUser] = useState<
    ScheduleUser | string | undefined
  >();
  const [selectedDate, handleDateChange] = useState<Date | null>(null);
  const [availableDates, setAvailableDates] = useState<string[]>([]);
  const [scheduleSelected, setScheduleSelected] = useState<string>('');
  const [stateDataInitStepsScheduling, handleChangeTypeScheduling] = useReducer(schedulingReducer, initialState)
  // Dependente
  const [errorNameDependente, setErrorNameDependente] = useState<
    undefined | string
  >(undefined);
  const [nameDependente, setNameDependente] = useState<string>('');
  const [errorCpfDependente, setErrorCpfDependente] = useState<
    undefined | string
  >(undefined);
  const [cpfDependente, setCpfDependente] = useState<string>('');
  const [birthDateDependente, setBirthDateDependente] = useState<Date>(
    subDays(new Date(), 30),
  );
  const [grauDependenteSelected, setGrauDependenteSelected] = useState<string>('');
  // Dados complementares
  const [formDataReason, setFormDataReason] = useState<ReasonStateProps>({
    descriptionProblem: '',
    reasonService: '',
  });
  const [files, setFiles] = useState<FileObject[]>([]);
  const [isErrorInUpload, setIsErrorInUpload] = useState(false);
  const [isAttachmentRequired, setIsAttachmentRequired] = useState(false);
  const [loading, setLoading] = useState<boolean>(false)
  const [savedSchedule, setSavedSchedule] =
    useState<SavedScheduleProps>(undefined);

  const handleChangeStep = (type: 'next' | 'prev') => {
    setStep((oldStep) => {
      if (type === 'next') {
        return oldStep + 1
      } else if (type === 'prev' && oldStep <= 0) {
        return 0
      }
      return oldStep - 1
    })
  }

  const handleChangeTypeScheduleOptions = ({ type, motivo }: TypeScheduleOptionsProps) => {
    setTypeSchedule(type);
    handleChangeTypeScheduling({
      type,
      payload: {
        motivo
      }
    })
  }

  const handleResetValues = () => {
    setFormDataReason({
      descriptionProblem: '',
      reasonService: '',
    });
    setFiles([])
    setIsAttachmentRequired(false)
    setErrorCpfDependente(undefined)
    setBirthDateDependente(subDays(new Date(), 30))
    setCpfDependente(null)
    setNameDependente(null)
    setScheduleSelected(null)
    handleDateChange(null)
    setScheduleServiceUser(null)
    setUnitySelected('')
    setUnitsList([])
    setStep(0)
    setTypeSchedule(null)
  }

  return (
    <ScheduleOnlineContext.Provider value={{
      activeStep: step,
      typeSchedule,
      handleChangeTypeScheduleOptions,
      unitsList,
      setUnitsList,
      unitySelected,
      setUnitySelected,
      scheduleService,
      setScheduleService,
      scheduleServiceUser,
      setScheduleServiceUser,
      selectedDate,
      scheduleSelected,
      setScheduleSelected,
      availableDates,
      setAvailableDates,
      handleDateChange,
      handleChangeStep,
      errorNameDependente,
      errorCpfDependente,
      setErrorCpfDependente,
      setErrorNameDependente,
      cpfDependente,
      setCpfDependente,
      stateDataInitStepsScheduling,
      nameDependente,
      grauDependenteSelected,
      setGrauDependenteSelected,
      setNameDependente,
      birthDateDependente,
      setBirthDateDependente,
      formDataReason,
      setFormDataReason,
      serviceData,
      setServiceData,
      files,
      setFiles,
      isAttachmentRequired,
      setIsAttachmentRequired,
      isErrorInUpload,
      setIsErrorInUpload,
      loading,
      setLoading,
      savedSchedule,
      setSavedSchedule,
      handleResetValues
    }}>
      {children}
    </ScheduleOnlineContext.Provider>
  )
}
